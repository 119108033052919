body {
  font-family: "Inter", "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  background-color: #f8f8f8;
}

body::-webkit-scrollbar,
div::-webkit-scrollbar {
  width: 10px;
}
body::-webkit-scrollbar-track,
div::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 100px;
}
body::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 100px;
}

.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #ff4800;
}

/* CUSTOM LETSGO */
.app-header .app-header-wrapper.layout-top-nav,
.app-content.layout-top-nav {
  max-width: 1800px !important;
}
.title_modal {
  border-left: 3px solid #ff4800;
  font-weight: 800;
}
h1.h1_dattas {
  font-size: 18px;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
h1.h1_dattas span {
  display: inline-block;
  margin-right: 0.8em;
  background-color: #e3fff2;
  padding: 0.5em 0.7em;
}
h1.h1_dattas span svg {
  color: #2dc49f;
}

#calendar_list .events_list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#calendar_list .events_list ul li {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  justify-content: space-between;
  transition: 0.2s ease-in-out;
}
#calendar_list .events_list ul li:hover .data_event {
  background-color: #f8f8f8;
}
#calendar_list .events_list ul li._duplicated {
  position: relative;
  margin-top: -0.9em;
}
/*#calendar_list .events_list ul li._duplicated .data_event{margin-top: -2em; }*/
#calendar_list .events_list ul li._duplicated {
  align-items: flex-end;
}
#calendar_list .events_list ul li._duplicated .date {
  padding: 1.3em 0 1.2em 0;
  border-radius: 6px;
}
/*#calendar_list .events_list ul li._duplicated .border_rounded{border-radius: 0 0 6px 6px!important; min-height: 65px;}*/

#calendar_list .events_list ul li._today .date {
  background-color: rgba(0, 0, 0, 0.08);
}

#calendar_list .events_list ul li.calendar_header.fixed {
  position: fixed;
  top: 70px;
  left: 0;
  margin: 0;
  padding: 0.35em 0.75em;
  background-color: #fff !important;
  z-index: 799;
  border-radius: 0 !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  width: 100%;
}
#calendar_list .events_list ul li.calendar_header div:nth-child(1) {
  color: #838383;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 9px;
}
#calendar_list .events_list ul li.calendar_header div:nth-child(1) img {
  margin: 0 0.5em;
}

#calendar_list .events_list ul li .date {
  display: flex;
  background-color: #f1f1f1;
  cursor: default;
  width: 5%;
  flex-direction: column;
  text-align: center;
  font-weight: 800;
  color: #676767;
  font-size: 16px;
  border-radius: 6px;
  padding: 0.7em 0.3em;
}
#calendar_list .events_list ul li .date.fds {
  background: rgb(248, 248, 248);
  background: linear-gradient(
    90deg,
    rgba(238, 238, 238, 1) 10%,
    rgba(223, 221, 226, 1) 100%
  );
}
#calendar_list .events_list ul li .date span {
  color: #676767;
  font-size: 11px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  display: block;
  margin-top: -0.5em;
}
#calendar_list .events_list ul li .date small {
  font-size: 8px;
  background-color: #feffbd;
  color: #1d3944;
  font-weight: 700;
  border-radius: 100px;
  text-transform: uppercase;
  padding: 0.15em 0.05em;
  display: inline-block;
  margin: 0.25em auto 0;
  width: 80%;
}

#calendar_list .events_list ul li .data_event {
  min-width: 94%;
  min-height: 50px;
  border-radius: 8px;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ececec;
}
#calendar_list .events_list ul li .data_event p.no_events {
  color: #b1b1b1;
  font-weight: 500;
  opacity: 0.85;
  padding-left: 1em !important;
}
#calendar_list .events_list ul li .data_event small {
  font-weight: 400;
}

#calendar_list .events_list ul li.agenda .date {
  background: #397e49;
  color: #fff;
}
#calendar_list .events_list ul li.agenda .date span {
  color: #fff;
}

#calendar_list .events_list ul li.pedidocotacao .date {
  background: #8e44ad;
  color: #fff;
}
#calendar_list .events_list ul li.pedidocotacao .date span {
  color: #fff;
}

#calendar_list .events_list ul li.compromisso .date {
  background: #fd9f2c;
  color: #fff;
}
#calendar_list .events_list ul li.compromisso .date span {
  color: #fff;
}

#calendar_list .events_list ul li.rascunho .date {
  background: #468beb;
  color: #fff;
}
#calendar_list .events_list ul li.rascunho .date span {
  color: #fff;
}

#calendar_list .events_list ul li.reserva .date {
  background: #d8252e;
  color: #fff;
}
#calendar_list .events_list ul li.reserva .date span {
  color: #fff;
}

#calendar_list .events_list ul li.cancelado {
  opacity: 0.2;
}
#calendar_list .events_list ul li.cancelado .date {
  background: #000;
  color: #fff;
}
#calendar_list .events_list ul li.cancelado .date span {
  color: #fff;
}

#calendar_list .events_list ul li.bloqueada .date {
  background: #000;
  color: #fff;
}
#calendar_list .events_list ul li.bloqueada .date span {
  color: #fff;
}

#calendar_list .events_list ul li.agenda .data_event,
#calendar_list .events_list ul li.pedidocotacao .data_event,
#calendar_list .events_list ul li.reserva .data_event,
#calendar_list .events_list ul li.compromisso .data_event,
#calendar_list .events_list ul li.bloqueada .data_event,
#calendar_list .events_list ul li.rascunho .data_event,
#calendar_list .events_list ul li.cancelado .data_event {
  color: #262626;
  font-weight: 600;
}

#calendar_list .events_list ul li.agenda .data_event .ico {
  color: #52c41a;
}
#calendar_list .events_list ul li.pedidocotacao .data_event .ico {
  color: #8e44ad;
}
#calendar_list .events_list ul li.reserva .data_event .ico {
  color: #f5222d;
}
#calendar_list .events_list ul li.compromisso .data_event .ico {
  color: #ffa940;
}
#calendar_list .events_list ul li.rascunho .data_event .ico {
  color: #468beb;
}
#calendar_list .events_list ul li.bloqueada .data_event .ico {
  color: #000000;
}
#calendar_list .events_list ul li.cancelado .data_event .ico {
  color: #666;
}

#calendar_list .events_list ul li .border_rounded {
  border-radius: 100px;
  background-color: #f1f1f1;
  min-height: 55px;
  min-width: 6px;
  display: flex;
  margin: 0;
  cursor: default;
}

#calendar_list .events_list ul li._today .border_rounded {
  min-height: 65px;
}
#calendar_list .events_list ul li.agenda .border_rounded {
  background-color: #52c41a;
}
#calendar_list .events_list ul li.pedidocotacao .border_rounded {
  background-color: #8e44ad;
}
#calendar_list .events_list ul li.reserva .border_rounded {
  background-color: #f5222d;
}
#calendar_list .events_list ul li.compromisso .border_rounded {
  background-color: #ffa940;
}
#calendar_list .events_list ul li.bloqueada .border_rounded {
  background-color: #000;
}

#calendar_list .events_list ul li .date.today {
  border-left: 5px solid #666;
}

/* CALENDAR LIST MODAL  */

#calendar_list.searchModal .events_list ul li .data_event {
  min-width: 90%;
}
#calendar_list.searchModal .events_list ul li .date {
  width: 7%;
}

.ant-card.calendar > [title]:hover {
  display: none;
}
.ant-card.calendar ul li .inside-calendar {
  display: none !important;
  transition: 0.2s ease-in-out;
  background-color: #fff !important;
  color: #222;
  border: none;
  border-radius: 6px;
  font-size: 10px;
  padding: 0;
  position: absolute;
  min-width: 95%;
  left: 2.5%;
  top: -35px;
  z-index: 9999;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.35);
}
.ant-card.calendar ul li:hover .inside-calendar {
  display: flex !important;
}
.ant-card.calendar ul li .inside-calendar:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -9px;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: 10px solid #fff;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
}

.ant-select-selection-item {
  font-weight: 500;
}

/*.ant-avatar{border-radius: 7px!important;}*/
.ant-col-24.ant-form-item-label > label,
.ant-col-xl-24.ant-form-item-label > label,
.ant-form-vertical .ant-form-item-label > label {
  width: 100%;
}
.logo_login {
  height: 100vh;
}
.logo_login img {
  max-width: 40%;
}

#logo_dattas img {
  max-width: 85px;
}
#calendar_list
  > div:nth-child(1)
  > div.ant-row.text-center.agenda_options_meses.bg-white.border.py-3.px-lg-5.no-hover
  > div.ant-col.px-lg-5.ant-col-md-24
  > button {
  width: 6%;
}

.side-nav.side-nav-dark {
  background-color: #36383e !important;
}
.avatar-calendar-list {
  display: none;
}

/* NEW LOGIN */
.new-login .ant-input-affix-wrapper:focus {
  box-shadow: rgba(0, 0, 25, 0.75) !important;
  border-color: #444 !important;
}

/* GAMBIARRA DOS INPUTS */
.ant-input,
.ant-input-affix-wrapper,
.ant-input-group-addon,
.ant-collapse .ant-collapse-header,
.ant-collapse .ant-collapse-item,
.ant-modal-content,
.ant-table table,
textarea.ant-input,
.ant-input-group > .ant-input:last-child,
.ant-btn,
.ant-select {
  /*padding-right: 1em;*/
  border-radius: 6px !important;
}

input.ant-input.edit-inline {
  padding: 0.5em !important;
}
input.ant-input.edit-inline:focus {
  box-shadow: none !important;
}
input.ant-input.edit-inline:focus {
  border: 1px solid #2dc49f !important;
}
.ant-select.edit-inline.ant-select-focused {
  border: 1px solid #2dc49f !important;
  max-width: 75% !important;
}
.ant-select.edit-inline.ant-select-focused .ant-select-selector {
  box-shadow: none !important;
  border: none !important;
}

small.edit-hover {
  transition: 0.3s ease-in-out;
  cursor: pointer;
  opacity: 0;
}
small.edit-hover svg {
  transition: 0.3s ease-in-out;
  color: rgba(0, 0, 0, 0.75);
}
small.edit-hover:hover,
small.edit-hover:hover svg {
  color: #3b3be5;
  opacity: 1;
}
.enableEdit:hover small.edit-hover {
  opacity: 1;
}

.antd-country-phone-input .ant-input,
.antd-country-phone-input .ant-select {
  padding: 0 !important;
}
.ant-input-affix-wrapper {
  padding: 0.25em 11px !important;
}

.ant-input::placeholder {
  color: rgba(0, 0, 0, 0.4) !important;
}
.ant-table-container table {
  margin-top: -2px;
}
.ant-table-container,
.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 2px !important;
}
.ant-table-container,
.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 2px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

/*.ant-modal-header{border-radius: 2px 2px 0 0;}*/
.ant-modal-footer {
  border-radius: 0 0 2px 2px !important;
}
.app-content.layout-top-nav {
  margin-top: 60px !important;
}
.app-header {
  position: relative;
}
.ant-modal.add .ant-modal-footer {
  text-align: center;
  padding: 1em 0;
  border-top: none;
}
.select_casting .ant-select-arrow {
  top: 55%;
}
.select_casting .ant-select-arrow span {
  font-size: 11px;
}

.dattas-logo img {
  width: auto;
  max-height: 38px;
}
.nav div.menuright {
  width: 100%;
  justify-content: flex-end;
  display: flex;
}

ul.ant-menu .ant-menu-sub {
  flex-direction: column;
}
ul.ant-menu.menu_logo_mobile {
  display: flex;
}
ul.ant-menu.menu_logo_mobile li {
  display: flex;
  align-items: center;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-submenu {
  border-bottom-color: transparent !important;
}
.ant-menu-horizontal:not(.ant-menu-dark).custom_menu > .ant-menu-submenu {
  margin: 0 0.05em;
}
.ant-menu-horizontal:not(.ant-menu-dark).custom_menu > .ant-menu-item {
  margin: 0;
  padding: 0;
}
.ant-menu-horizontal:not(.ant-menu-dark).custom_menu > .ant-menu-item span,
.ant-menu-horizontal:not(.ant-menu-dark).custom_menu > .ant-menu-submenu span {
  padding: 1em 0.6em;
  font-size: 13px;
  border-color: #fff;
}

.ant-menu-horizontal:not(.ant-menu-dark).custom_menu
  > .ant-menu-item:hover
  span,
.ant-menu-horizontal:not(.ant-menu-dark).custom_menu
  > .ant-menu-submenu:hover
  span,
.ant-menu-submenu.ant-menu-submenu-horizontal.ant-menu-submenu-open.ant-menu-submenu-active
  span {
  background-color: #1d3944;
  color: #fff;
  border-radius: 6px;
  border-bottom-color: #fff !important;
}

.nav-right .ant-menu-horizontal .ant-menu-item {
  display: flex;
  align-items: center;
  height: 100%;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
  border-color: transparent;
}
.nav-right .ant-menu-horizontal .ant-menu-item .count_notifications {
  background-color: #2dc49f;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: inherit;
}
.nav-right .ant-menu-horizontal .ant-menu-item button.count_notifications:hover,
.nav-right .ant-menu-horizontal .ant-menu-item button.count_notifications:focus,
.nav-right
  .ant-menu-horizontal
  .ant-menu-item
  button.count_notifications:active {
  background-color: rgb(29, 57, 68) !important;
  color: #fff !important;
}

/* SUBMENU */
.ant-menu-submenu-popup {
  margin-top: -100px !important;
}
.ant-menu-submenu ul li.ant-menu-item {
  color: #5e6278;
  font-weight: 500;
}
.ant-menu-submenu ul li.ant-menu-item:hover {
  color: #2dc49f;
  background-color: #e3fff2;
}

/* CALENDARIO */
.ant-picker-calendar-full .ant-picker-body .ant-picker-content thead tr th {
  text-align: center !important;
  color: #5e6278;
  font-weight: 300;
  font-size: 12px;
  padding: 0 0 1em 0;
}

.ant-picker-cell.ant-picker-cell-start.ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner.ant-picker-calendar-date {
  background-color: #fff;
}
.ant-picker-cell.ant-picker-cell-start.ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-calendar-date-value {
  color: #2dc49f;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
  color: #2dc49f;
  font-weight: 500;
  letter-spacing: -0.25px;
}
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-calendar-date-today
  .ant-picker-calendar-date-value {
  background-color: #2dc49f;
  color: #feffbd !important;
  letter-spacing: -0.25px;
  padding: 0.01em 0.4em;
  font-weight: 500 !important;
}
.ant-picker-cell-inner.ant-picker-calendar-date.ant-picker-calendar-date-today {
  background-color: #fefff2;
}

/* TRAÇAR ROTAS */
.map {
  height: 90vh;
}
.map-marker {
  margin-top: -30px;
}
.bottom_routes {
  display: none;
}
.bottom_routes span {
  display: block;
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 600;
}
.bottom_routes img.icon {
  max-width: 39px;
  max-height: 39px;
  width: 39px;
  height: 39px;
  margin: 0;
}

.left_routes {
  background: #fff;
  display: block;
  border: 1px solid rgba(161, 165, 183, 0.2);
  box-shadow: -3px 0px 0px rgba(161, 165, 183, 0.2);
  border-radius: 6px;
}
.left_routes .roteiro {
  background-color: rgba(236, 236, 236, 0.5);
  border-radius: 6px;
}
.left_routes img {
  max-width: 24px;
}
.left_routes .ant-col {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}
.left_routes .ant-col span {
  font-weight: 700;
}

.routes ul {
  list-style: none;
  margin: 0.8em 0 0 0;
  padding: 0;
  user-select: none;
}
.routes ul li {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 0.5em;
}
.routes ul li img {
  max-height: 24px !important;
}
/*.routes ul li::after{content: ""; position: absolute; top: 21px; width: 1.5px; height: 20px; display: block; background-color: rgba(0,0,0,.1);}*/
.routes ul li:last-child::after {
  display: none;
}
.routes ul li span {
  border: 1px solid #1d3944;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  min-width: 21px;
  min-height: 21px;
  max-width: 21px;
  max-height: 21px;
  font-size: 10px;
  font-weight: 600;
}

.gradient-smooth {
  background: linear-gradient(
      352.43deg,
      hsla(0, 0%, 100%, 0.2),
      rgba(200, 255, 235, 0.2) 78.01%,
      rgba(254, 255, 191, 0.2)
    ),
    #fff;
}

/* HEADER MENU */

.ant-form-item-control-input-content input,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 6px !important;
}

.app-header.light .ant-badge {
  margin-top: 1.25em;
}
.page-header-alt {
  background-color: transparent !important;
  border-bottom: none !important;
}
.ant-modal-mask {
  background-color: rgba(0, 0, 25, 0.75);
}
.legenda h5 {
  font-size: 13px !important;
  color: #37383e;
  font-weight: 700;
  padding-top: 1px;
  margin-bottom: 0;
}
.legenda .ant-badge-status-text {
  margin-left: 4px;
}
.legenda .ant-row {
  border-radius: 0 0 12px 12px !important;
}

#rc-tabs-3-panel-1
  > div
  > div:nth-child(7)
  > div
  > div.ant-picker-calendar-header
  > div.ant-radio-group.ant-radio-group-outline.ant-radio-group-small.ant-picker-calendar-mode-switch {
  display: none !important;
}
.datepicker---date-picker-container---1kNBY {
  width: 100%;
  margin-bottom: 0.5em;
}
.datepicker---date-picker-container---1kNBY .react-datepicker__day--selected {
  background-color: #36383e !important;
}

.table_financas .ant-table {
  font-size: 10px !important;
  text-align: center !important;
}
.table_financas .ant-table table tr th,
.table_financas .ant-table table tr td {
  padding: 1em 0;
  text-align: center;
}

.ant-card.cardboard .ant-card-head-title {
  text-align: center;
  font-size: 15px;
}

.ant-card.widget_custom {
  border: none !important;
}
.ant-card.widget_custom .ant-card-body {
  padding: 0.5em 0 !important;
}
.ant-card.widget_custom .ant-card-head-title {
  font-size: 14px;
  font-weight: 600;
}

.ant-card.widget_custom
  .ant-picker-date-panel
  .ant-picker-calendar-date-content
  .events
  .ant-badge {
  margin-right: 0.5em;
}
.ant-card.widget_custom
  .ant-picker-date-panel
  .ant-picker-calendar-date-content
  .events
  .ant-badge:last-child {
  margin-right: 0;
}
.ant-card.widget_custom
  .ant-picker-date-panel
  .ant-picker-calendar-date-content
  .events
  .ant-badge
  .ant-badge-status-text {
  display: none;
}
.ant-card.widget_custom
  .ant-picker-date-panel
  .ant-picker-calendar-date-content
  .events
  li {
  margin-right: 0.1em !important;
}
.ant-card.widget_custom
  .ant-picker-date-panel
  .ant-picker-calendar-date-content
  .events
  li:last-child {
  margin-right: 0 !important;
}
.ant-card.widget_custom
  .ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner {
  background-color: transparent;
  color: #000;
}
.ant-card.widget_custom .ant-picker-date-panel .ant-picker-calendar-date-today {
  background-color: #545662;
  color: #fff;
  border-color: transparent !important;
}
.ant-card.widget_custom
  .ant-picker-date-panel
  .ant-picker-calendar-date-today:hover {
  background-color: #000 !important;
  color: #fff !important;
  border-color: transparent !important;
}
.ant-card.widget_custom .ant-picker-date-panel .ant-picker-cell {
  padding: 0;
}
.ant-card.widget_custom .ant-picker-date-panel .ant-picker-content thead tr th {
  font-weight: 700;
  font-size: 12px;
  padding-bottom: 1em;
}
.ant-card.widget_custom
  .ant-picker-date-panel
  .ant-picker-cell
  .ant-picker-cell-inner {
  line-height: 26px;
}

.ant-card.widget_custom.regions span {
  position: absolute;
  left: 1em;
  color: #fff;
  bottom: 0.05em;
  font-size: 12px;
  padding-top: 0.25em;
}
.ant-card.widget_custom.regions .ant-progress-outer {
  margin-top: 0.5em;
}
.ant-card.widget_custom.regions .ant-progress-inner {
  background-color: transparent;
}
.ant-card.widget_custom.regions .ant-progress-bg {
  height: 20px !important;
}

.ant-card.widget_custom.stats p {
  font-weight: 600;
}
.ant-card.widget_custom.stats p strong {
  font-size: 40px;
  line-height: 35px;
  margin: 0;
  padding: 0;
  font-weight: 200;
  color: #37383e;
  display: inline-block;
}
.ant-card.widget_custom.stats span {
  font-size: 11px;
  color: #b5b5c3;
  font-weight: 500;
}
.ant-card.widget_custom.stats span strong {
  color: #50cd89;
  font-weight: 700;
}

.ant-card.cardboard .btn_quick_access {
  background-color: rgba(0, 0, 0, 0.05) !important;
  opacity: 0.6;
  transition: 0.2ms ease-in-out;
  height: auto;
  text-align: center;
  padding: 1em 0.5em;
  width: 100%;
  text-transform: uppercase;
}
.ant-card.cardboard .btn_quick_access .round_icon {
  border: 2px solid #444;
  margin: 0 auto 0.25em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
.ant-card.cardboard .btn_quick_access svg {
  margin: 0 auto;
  font-size: 17px;
}
.ant-card.cardboard .btn_quick_access span {
  opacity: 0.65;
  font-weight: 600;
  font-size: 10px;
}
.ant-card.cardboard .btn_quick_access:hover {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.09) !important;
}

.ant-card.cardboard .ant-btn[disabled] > * {
  opacity: 0.4 !important;
}
.ant-card.cardboard .btn_quick_access[disabled] .round_icon {
  border-color: #999;
}

.ant-card.cardboard .btn_quick_access.success svg {
  color: #10ac84;
}
.ant-card.cardboard .btn_quick_access.success .round_icon {
  border-color: #10ac84;
}
.ant-card.cardboard .btn_quick_access.danger svg {
  color: #ee5253;
}
.ant-card.cardboard .btn_quick_access.danger .round_icon {
  border-color: #ee5253;
}
.ant-card.cardboard .btn_quick_access.orange svg {
  color: #ff9f43;
}
.ant-card.cardboard .btn_quick_access.orange .round_icon {
  border-color: #ff9f43;
}
.ant-card.cardboard .btn_quick_access.purple svg {
  color: #8e44ad;
}
.ant-card.cardboard .btn_quick_access.purple .round_icon {
  border-color: #8e44ad;
}

.ant-card.cardboard .last_dates .ant-collapse-item {
  border-bottom-color: #ddd;
}
.ant-card.cardboard .last_dates .ant-collapse-item .ant-collapse-header {
  padding: 1em 0;
}
.ant-card.cardboard .last_dates .ant-collapse-item .ant-collapse-content-box {
  padding: 0 0.1em;
}
.ant-card.cardboard .last_dates .ant-collapse-item .ant-collapse-header small {
  display: block;
  margin-bottom: 0.1em;
  font-size: 11px;
  font-weight: 700;
  color: #2980b9;
}
.ant-card.cardboard .last_dates .ant-collapse-item .ant-collapse-header span {
  display: block;
  margin-bottom: 0.1em;
  color: #333;
  font-weight: 400;
  font-size: 15px;
}
.ant-card.cardboard
  .last_dates
  .ant-collapse-item
  .ant-collapse-header
  small:last-child {
  color: #999;
  font-size: 10px;
  font-weight: 500;
}
.ant-card.cardboard
  .last_dates
  .ant-collapse-item
  .ant-collapse-header
  span.details {
  float: right;
  color: #bbb;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
}

.browser {
  position: absolute;
  padding-left: 4px;
  padding-right: 4px;
  background: #fff;
  z-index: 5555;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  top: 70px;
}
.browser.data_fim {
  left: 365px;
}

.ant-modal.fullscreen {
  max-width: 100% !important;
  min-height: 100vh !important;
  padding-bottom: 0 !important;
}
.ant-modal.fullscreen .ant-modal-body {
  padding: 0 2.5em;
}
.ant-modal.fullscreen .ant-modal-content {
  box-shadow: none;
}

button.btn_add_ordem {
  border-radius: 12px !important;
  width: auto;
  height: 32px;
  padding: 0 0.75em;
  border-color: #195ab4;
  transition: 0.25s ease-in-out;
}
button.btn_add_ordem span {
  transition: 0.25s ease-in-out;
  color: #195ab4;
}
button.btn_add_ordem small {
  transition: 0.2s ease-in-out;
  color: #195ab4 !important;
  margin-left: 0.25em;
  font-weight: 600;
}
button.btn_add_ordem:hover {
  background-color: #195ab4 !important;
  width: auto;
}
button.btn_add_ordem:hover small {
  display: inline-block;
  color: #fff !important;
}
button.btn_add_ordem:hover span {
  color: #fff;
}

/* HEADER V2 */

.ant-form-item-label > label,
.ant-picker-cell-in-view.ant-picker-cell-today {
  background-color: linear-gradient(
    165.04deg,
    #fcfff4 11.15%,
    #f4fffb 22.92%,
    #feffff 58.83%,
    rgba(254, 255, 255, 0) 90.07%
  );
}

.editable_block .ant-select {
  min-width: 100%;
}
.editable_block input.ant-input,
.editable_block .ant-select .ant-select-selector {
  border: none;
  margin: 0;
  border-bottom: none !important;
  border-radius: 0px !important;
  font-size: 12px;
}
.editable_block input.ant-input:focus,
.editable_block .ant-select.ant-select-focused .ant-select-selector {
  box-shadow: none !important;
  border-color: transparent !important;
}
.editable_block input.ant-input:hover,
.editable_block .ant-select .ant-select-selector:hover {
  border: none !important;
}
.editable_block .ant-select .ant-select-selector {
  padding: 0 !important;
}
.editable_block .ant-select .ant-select-selector .ant-select-selection-search {
  left: 0 !important;
}

.dattas_loading span {
  font-size: 10px;
  font-weight: 500;
  opacity: 0.7;
  cursor: default;
}
.dattas_loading img {
  max-width: 100px;
  -webkit-animation: breathing 5s ease-out infinite normal;
  animation: breathing 5s ease-out infinite normal;
}

.ant-select.select_status .ant-select-selector {
  background-color: #e3fff2;
  height: 28px !important;
  color: #397e49;
  border-radius: 100px !important;
  border: 1px solid #397e49;
  padding: 0 1.5em;
}
.ant-select.select_status .ant-select-selector .ant-select-selection-item {
  line-height: 28px !important;
  padding-left: 0.5em;
}

.ant-select.select_status.status_2 .ant-select-selector,
.ant-select.select_status.status_3 .ant-select-selector {
  color: #000;
  border-color: #999;
  background-color: rgba(0, 0, 0, 0.025);
}

.box_data {
  border: 1px solid rgba(161, 165, 183, 0.2);
  box-shadow: -3px 0px 0px rgba(161, 165, 183, 0.2);
  border-radius: 6px;
}
.custom-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #2dc49f;
}
.custom-tab .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  border-color: #2dc49f;
}
.custom-tab .ant-tabs-ink-bar {
  height: 5px;
  background: #2dc49f;
}

@-webkit-keyframes breathing {
  0% {
    opacity: 0.2;
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  50% {
    opacity: 0.6;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  100% {
    opacity: 0.2;
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes breathing {
  0% {
    opacity: 0.2;
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  50% {
    opacity: 0.6;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  100% {
    opacity: 0.2;
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}

#calendar_list .events_list ul li .data_event span {
  text-transform: capitalize !important;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-checked
  span.ant-radio-button.ant-radio-button-checked {
  background-color: rgb(29, 57, 68) !important;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-checked span {
  color: #fff;
}

.radio_group
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked
  span.ant-radio-button.ant-radio-button-checked {
  background-color: transparent !important;
}
.radio_group label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background: #2dc49f;
  border-color: #2dc49f;
  border-right-color: #17b18a;
  border-left-color: #17b18a;
}

.ant-input-group-addon:last-child,
.ant-input-group-addon:last-child .ant-select .ant-select-selector,
.ant-input-group > .ant-input:last-child,
.ant-input-group > .ant-input:last-child .ant-select .ant-select-selector {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  user-select: none;
}
.ant-input-group-addon:first-child,
.ant-input-group-addon:first-child .ant-select .ant-select-selector,
.ant-input-group > .ant-input:first-child,
.ant-input-group > .ant-input:first-child .ant-select .ant-select-selector {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  user-select: none;
}

.fixed_header_web {
  position: sticky;
  background-color: #fff;
  min-width: 1103px;
  top: 65px;
  z-index: 5;
}

/* FILE MANAGER */
.search_documents input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  padding: 8px 11px !important;
  padding-right: 1em !important;
}

/* SEARCH MODAL */
#search-modal .ant-input-affix-wrapper {
  padding: 0.1em 0.2em !important;
}

/* RESPONSIVIDADE */
@media screen and (max-width: 767px) {
  .app-content {
    padding: 5px;
  }
  .app-header {
    z-index: 800 !important;
  }
  .app-header.ant-layout-header .ant-menu-item {
    padding-right: 0 !important;
  }
  .legenda h5 {
    font-size: 12px !important;
    padding-top: 2px;
  }
  .ant-modal-body {
    padding: 10px !important;
  }
  #slide_casting {
    padding-left: 250px !important;
    justify-content: start;
  }
  #calendar_list .ant-col {
    max-width: 100% !important;
    width: 100%;
  }
  #calendar_list ul {
    min-height: 70px;
  }
  .page-header-alt {
    padding: 25px 0 15px 0 !important;
  }
  .centerMobileOnly {
    text-align: center !important; /*margin: 0 0 .5em 0;*/
  }
  .avatar-calendar-list .ant-avatar {
    width: 25px;
    height: 25px;
    font-size: 0.7em;
  }
  .logo_login {
    height: auto;
    padding: 0 0 4em 0;
  }
  .logo_login img {
    max-width: 55%;
  }
  #logo_dattas img {
    max-width: 100px;
  }
  .ant-btn#float_add {
    position: fixed;
    width: 60px !important;
    height: 60px !important;
    background-color: #ff6400;
    bottom: 2em;
    right: 1.5em;
    color: #000;
    border-radius: 100% !important;
    z-index: 999;
    border-color: #ff6400;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.45);
  }
  .ant-btn#float_add svg {
    scale: 1.15;
  }
  .ant-btn#float_add.gotoday {
    width: 55px !important;
    height: 55px !important;
    background-color: #17b18a;
    border-color: #17b18a;
    color: #fff;
    bottom: 2em;
    right: 25%;
  }
  #calendar_list.fixed .ant-row {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
  }
  #calendar_list.fixed .ant-row:nth-child(2) {
    top: 55px;
    z-index: 5;
  }
  .nav-search.light {
    display: none;
  }
  .slide_casting.swiper .swiper-wrapper {
    justify-content: start;
  }
  #calendar_list
    > div:nth-child(1)
    > div.ant-row.text-center.agenda_options_meses.bg-white.border.py-3.px-lg-5.no-hover
    > div.ant-col.px-lg-5.ant-col-md-24
    > button {
    width: 13.5%;
  }
  .nav-left .ant-select.ant-select-single {
    min-width: 200px !important;
  }
  /* modal fullscreen */
  .ant-modal.fullscreen,
  .fullscreen .ant-modal-content {
    width: 100vw !important;
    margin: 0 !important;
    top: 0 !important;
    padding-right: 0.5em;
    border-radius: 0 !important;
    overflow-x: hidden;
  }
  .fullscreen .ant-modal-footer {
    padding-bottom: 2em !important;
    background-color: #fff !important;
  }
  button.btn_add_ordem {
    margin-top: 0;
  }

  .ant-select.edit-inline {
    max-width: 50% !important;
  }

  .option_fixed_header {
    position: absolute;
    top: -3.62rem;
    right: 5em;
    z-index: 999;
  }
  .option_fixed_header.opened {
    top: -8.25rem;
  }
  .option_fixed_header.opened_months {
    top: -14.85rem !important;
  }

  #calendar_list {
    margin-bottom: 1em;
  }
  #calendar_list .events_list ul li {
    margin: 0.05em 0.2em;
    padding: 0.1em;
    margin-bottom: 0.25em;
    width: 98%;
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: 6px !important;
  }
  #calendar_list .events_list ul li:first-child {
    background: none !important;
  }
  #calendar_list .events_list ul li .date {
    width: 12%;
    background: none;
    color: #676767;
    padding: 0.5em 0.25em 0.5em 0;
    font-size: 18px;
    letter-spacing: -1px;
  }
  #calendar_list .events_list ul li .date.mini {
    font-size: 11px;
  }
  #calendar_list .events_list ul li .data_event {
    min-height: auto;
  }
  #calendar_list .events_list ul li .date.mini span {
    font-size: 9px;
    font-weight: 600;
    letter-spacing: 0;
  }
  #calendar_list .events_list ul li .date.fds {
    background: rgb(248, 248, 248);
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.1) 10%,
      rgba(223, 221, 226, 1) 100%
    );
  }
  #calendar_list .events_list ul li .date span {
    color: #676767;
    font-size: 0.7em;
  }
  #calendar_list .events_list ul li .date small {
    background-color: #2dc49f !important;
    font-weight: 600;
    font-size: 0.5em;
  }
  #calendar_list .events_list ul li .date {
    border-left: 5px solid transparent;
  }
  #calendar_list .events_list ul li .date.today {
    border-left: 5px solid #666;
  }
  #calendar_list .events_list ul li .data_event {
    min-width: 86.5%;
    max-width: 80%;
    margin-right: 3px;
    border: none;
    padding: 0.5em 0.55em !important;
    background-color: #fff;
    border: 1px solid #ececec;
  }
  #calendar_list .events_list ul li .data_event span {
    padding-left: 0.5em;
    font-size: 1em;
  }
  #calendar_list .events_list ul li .data_event small {
    font-size: 0.8em;
    font-weight: 400;
    font-weight: normal;
  }
  #calendar_list .events_list ul li .data_event small strong {
    font-weight: 600;
  }
  #calendar_list .events_list ul li .data_event p.no_events {
    padding-left: 0.5em !important;
    line-height: 17.5px;
  }

  #calendar_list .events_list ul li .data_event svg {
    display: none;
  }
  #calendar_list .events_list ul li .border_rounded {
    background-color: #fff;
    display: none;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
  }

  #calendar_list .events_list ul li.fds,
  #calendar_list .events_list ul li.reserva .date,
  #calendar_list .events_list ul li.compromisso .date,
  #calendar_list .events_list ul li.bloqueada .date {
    background: linear-gradient(
      90deg,
      #cbcbcb 0.01%,
      rgba(0, 0, 0, 0.15) 0%,
      rgba(0, 0, 0, 0.08) 15.5%
    );
  }
  #calendar_list .events_list ul li.agenda .date,
  #calendar_list .events_list ul li.reserva .date,
  #calendar_list .events_list ul li.compromisso .date,
  #calendar_list .events_list ul li.bloqueada .date {
    background: none !important;
    color: #676767;
  }
  #calendar_list .events_list ul li.agenda .date span,
  #calendar_list .events_list ul li.reserva .date span,
  #calendar_list .events_list ul li.compromisso .date span,
  #calendar_list .events_list ul li.bloqueada .date span {
    color: #676767;
  }

  #calendar_list .events_list ul li._duplicated {
    background: none;
  }

  #calendar_list .events_list ul li.agenda .data_event {
    background: #397e49;
    color: #fff !important;
  }
  #calendar_list .events_list ul li.agenda .data_event small {
    color: #fff !important;
  }
  #calendar_list .events_list ul li.compromisso .data_event {
    background: #fd9f2c;
    color: #fff;
  }
  #calendar_list .events_list ul li.compromisso .data_event small {
    color: #fff !important;
  }
  #calendar_list .events_list ul li.reserva .data_event {
    background: #d8252e;
    color: #fff;
  }
  #calendar_list .events_list ul li.reserva .data_event small {
    color: #fff !important;
  }
  #calendar_list .events_list ul li.bloqueada .data_event {
    background: #000;
    color: #fff !important;
  }
  #calendar_list .events_list ul li.bloqueada .data_event small {
    color: #fff !important;
  }

  #calendar_list .events_list ul li._duplicated {
    margin-top: auto !important;
  }
  #calendar_list .events_list ul li._duplicated {
    align-items: flex-end;
  }
  #calendar_list .events_list ul li._duplicated .date {
    padding-bottom: 0;
    padding-top: 0;
    opacity: 0;
  }

  #calendar_list .events_list ul li._duplicated {
    margin-top: auto !important;
  }
  #calendar_list .events_list ul li._duplicated {
    align-items: flex-end;
  }
  #calendar_list .events_list ul li.bloqueada .data_event {
    background: #000;
    color: #fff !important;
  }
  #calendar_list .events_list ul li.bloqueada .data_event small {
    color: #fff !important;
  }

  #calendar_list .events_list ul li._duplicated {
    margin-top: auto !important;
  }
  #calendar_list .events_list ul li._duplicated {
    align-items: flex-end;
  }
  .bottom_routes {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #fff;
    width: 100%;
  }
  .left_routes {
    display: none;
  }
  .map {
    max-height: 100%;
  }
}
